<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        type="primary"
        icon="el-icon-edit"
        @click="dialogVisible = true"
        >添加</el-button
      >
    </div>
    <el-table
      v-loading="listLoading"
      :data="list"
      element-loading-text="Loading"
      fit
      highlight-current-row
    >
      <el-table-column align="center" label="序号" width="60">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
          <!-- {{ scope.row.id }} -->
        </template>
      </el-table-column>
      <el-table-column label="分账方名称" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.role_name }}
        </template>
      </el-table-column>
      <el-table-column label="金额" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.money }}元 </template>
      </el-table-column>
      <el-table-column label="比例" min-width="110" align="center">
        <template slot-scope="scope"> {{ scope.row.proportion }}% </template>
      </el-table-column>
      <el-table-column label="收入方式" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-if="scope.row.pay_method == 'userId'">支付宝UID</div>
          <div v-else>支付宝登录账号</div>
        </template>
      </el-table-column>
      <el-table-column label="账号" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.account }}
        </template>
      </el-table-column>
      <el-table-column label="是否使用" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_use">是</el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="是否结余再分账" min-width="110" align="center">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.is_balance_strike">是</el-tag>
          <el-tag type="danger" v-else>否</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="优先级" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.weight }}
        </template>
      </el-table-column>
      <!-- <el-table-column label="手机号" min-width="110" align="center">
        <template slot-scope="scope">
          <div v-for="(item, index) in scope.row.contact" :key="index">
            {{ item }}
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        label="操作"
        fixed="right"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            type="success"
            size="mini"
            v-if="scope.row.is_use == 0"
            @click="handleUse(scope.row)"
            >使用</el-button
          >
          <el-button
            type="danger"
            size="mini"
            v-else
            @click="handleUse(scope.row)"
            >禁用</el-button
          >
          <el-button
            type="warning"
            size="mini"
            @click="handleToPhones(scope.row)"
            >手机号列表</el-button
          >
          <el-button
            type="success"
            size="mini"
            @click="revenueLog(scope.row)"
            >分账收入记录</el-button
          >
          <el-button type="danger" size="mini" @click="handleDel(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination-container">
      <el-pagination
        :current-page="listQuery.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="listQuery.limit"
        :total="total"
        background
        layout="total, sizes, prev, pager, next, jumper"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="50%"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="left"
        label-width="120px"
      >
        <el-form-item label="分账方名称" prop="name" style="width: 50%">
          <el-input v-model="form.role_name" placeholder="请输入分账方名称" />
        </el-form-item>
        <el-form-item label="金额" prop="name" style="width: 65%">
          <div class="input">
            <el-input v-model="form.money" placeholder="请输入金额" />
            <span>元</span>+
            <el-input v-model="form.proportion" placeholder="请输入比例" />
            <span>%</span>
          </div>
        </el-form-item>
        <!-- <el-form-item label="比例" prop="name">
         <div class="input">
            <el-input v-model="form.income" placeholder="请输入比例" />
            <span>{{  "%"  }}</span>
          </div>
        </el-form-item> -->
        <el-form-item label="收款账号" prop="name" style="width: 90%">
          <el-input
            v-if="form.pay_method == 'loginName'"
            v-model="form.account"
            placeholder="请输入支付宝登录账号"
            :disabled="!!form.id"
          />
          <el-input
            v-if="form.pay_method == 'userId'"
            v-model="form.account"
            placeholder="请输入支付宝UID"
            :disabled="!!form.id"
          />
        </el-form-item>
        <el-form-item label="收入方式">
          <el-radio v-model="form.pay_method" label="loginName" :disabled="!!form.id"
            >支付宝登录账号</el-radio
          >
          <el-radio v-model="form.pay_method" label="userId" :disabled="!!form.id"
            >支付宝UID</el-radio
          >
        </el-form-item>
        <el-form-item
          label="账号真实姓名"
          prop="name"
          style="width: 90%"
          v-if="form.pay_method == 'loginName'"
        >
          <el-input v-model="form.name" placeholder="请输入账号真实姓名" :disabled="!!form.id" />
        </el-form-item>
<!--        <el-form-item label="特殊分账商家" prop="name" style="width: 90%">-->
<!--          <el-button-->
<!--            icon="el-icon-plus"-->
<!--            size="small"-->
<!--            plain-->
<!--            @click="addShop"-->
<!--          ></el-button>-->
<!--          <div v-for="(item, index) in form.shops" :key="index">-->
<!--            <el-select-->
<!--              v-model="item.shop_id"-->
<!--              @visible-change="getShopList"-->
<!--              :loading="loading"-->
<!--              style="width: 30%; margin-right: 5px; margin-top: 5px"-->
<!--            >-->
<!--              <el-option-->
<!--                v-for="shop in shopList"-->
<!--                :key="shop.id"-->
<!--                :label="shop.shop_name"-->
<!--                :value="shop.id"-->
<!--              >-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-input-->
<!--              v-model="item.money"-->
<!--              placeholder="请输入金额"-->
<!--              style="width: 25%"-->
<!--            />-->
<!--            <span>元</span>+-->
<!--            <el-input-->
<!--              v-model="item.proportion"-->
<!--              placeholder="请输入比例"-->
<!--              style="width: 25%"-->
<!--            />-->
<!--            <span>%</span>-->
<!--            <el-button-->
<!--              icon="el-icon-delete"-->
<!--              size="small"-->
<!--              plain-->
<!--              @click="delShop(index)"-->
<!--            ></el-button>-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item label="是否结余再分账" prop="is_balance_strike">
          <el-switch v-model="form.is_balance_strike" :active-value="1" :inactive-value="0"></el-switch>
        </el-form-item>
        <el-form-item label="优先级" prop="weight">
          <el-input-number v-model="form.weight" :min="1"></el-input-number>
          <div style="line-height:16px;font-size: 12px;color:red;margin-top:10px;">
            <div>*优先级越小越优先结算</div>
            <div>*每个店铺的配送账户应该排在第一位</div>
            <div>*配送账户将按照订单配送费进行分账,不再按此页面金额计算</div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveData" :loading="btnLoading"
          >确定</el-button
        >
      </div>
    </el-dialog>

    <el-dialog title="支付方式修改" :visible.sync="dialogVisible1" width="30%">
      <el-radio-group v-model="pay_method.pay_method">
<!--        <el-radio label="alipay">支付宝支付</el-radio>-->
<!--        <el-radio label="ccb">建行支付</el-radio>-->
        <el-radio label="sp">服务商支付</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">取 消</el-button>
        <el-button type="primary" @click="handleStore">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import request from "@/utils/request";
import AMapPosition from "@/components/AMapPosition";
import { mapGetters } from "vuex";

export default {
  components: {
    AMapPosition,
  },
  data() {
    return {
      list: null,
      total: null,
      loading: false,
      btnLoading: false,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 10,
        school_id: "",
      },
      shopList: [],
      dialogVisible: false,
      dialogVisible1: false,
      form: {
        id: null,
        school_id: "",
        role_name: "",
        money: "",
        proportion: "",
        pay_method: "userId",
        account: "",
        name: "",
        shops: [],
        is_balance_strike: 0,
        weight: 1,
      },
      pay_method: {
        id: "",
        pay_method: "",
      },
      inputVisible: false,
      inputValue: "",
    };
  },
  created() {
    this.getList();
  },
  computed: {
    ...mapGetters(["school_id"]),
  },
  watch: {
    school_id(newVal, oldVal) {
      this.listQuery.school_id = newVal;
      this.form.school_id = newVal;
      this.getList();
    },
    dialogVisible(newVal, oldVal) {
      // 编辑框一异隐藏，马上清除旧数据
      if (newVal === false) {
        this.form = {
          id: null,
          school_id: "",
          role_name: "",
          money: "",
          proportion: "",
          pay_method: "userId",
          account: "",
          name: "",
          shops: [],
          is_balance_strike: 0,
          weight: 1,
        };
      }
    },
  },
  methods: {
    getList() {
      this.listLoading = true;
      this.listQuery.school_id = this.school_id;
      request({
        url: "/api/backend/school/ledgerRoleList",
        method: "get",
        params: this.listQuery,
      }).then((response) => {
        this.list = response.data.data;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    getShopList(e) {
      this.loading = true;
      if (e) {
        const shop_ids = [];
        this.form.shops.forEach((element) => {
          // console.log(element);
          if (element.shop_id) {
            shop_ids.push(element.shop_id);
          }
        });
        request({
          url: "/api/backend/school/shopList",
          method: "get",
          params: {
            shop_ids: shop_ids,
            school_id: this.form.school_id,
          },
        }).then((response) => {
          this.shopList = response.data;
          this.loading = false;
        });
      }
    },
    choosePosition(res) {
      console.log(res);
      this.form.lng = res.position.lng;
      this.form.lat = res.position.lat;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleSizeChange(val) {
      this.listQuery.limit = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.listQuery.page = val;
      this.getList();
    },
    handleClose(tag) {
      this.form.contact.splice(this.form.contact.indexOf(tag), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.form.contact.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    async handleEdit(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.getLedgerRoleDetail(item.id);
      this.dialogVisible = true;
    },
    getLedgerRoleDetail(id) {
      request({
        url: "/api/backend/school/ledgerRoleDetail",
        method: "get",
        params: { id: id },
      }).then((response) => {
        this.form = response.data;
        this.form.shops = this.form.shops == null ? [] : this.form.shops;
        console.log(this.form);
        request({
          url: "/api/backend/school/shopList",
          method: "get",
          params: {
            school_id: this.form.school_id,
          },
        }).then((response) => {
          this.shopList = response.data;
        });
      });
    },
    async handleDel(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.$confirm("确定要删除该分账角色吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/ledgerRoleDel",
          method: "get",
          params: {
            id: item.id,
          },
        }).then(() => {
          // 删除最后一条数据时无数据问题
          this.list.length <= 1 ? this.listQuery.page-- : false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
    async handleUse(item) {
      // 操作密码
      const { password } = await this.$oprationPassword()
      await request({
        url: "/api/backend/school/checkOperationPassword",
        method: "get",
        params: {
          id: item.school_id,
          operation_password: password,
        },
      }).then(() => {

      }).catch(() => {
        return new Promise((resolve, reject) => {
          this.promise = { resolve, reject } // 将resolve 、reject暂存起来,方便调用
        })
      });

      this.listLoading = true;
      request({
        url: "/api/backend/school/use",
        method: "get",
        params: {
          id: item.id,
        },
      }).then(() => {
        this.getList();
        this.$store.dispatch("GetSchools");
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
    saveData() {
      if (!this.form.role_name) {
        this.$message({
          type: "warning",
          message: "请输入分账方名称",
        });
        return;
      }
      let b = true;
      this.form.shops.forEach((element) => {
        if (!element.shop_id) {
          this.$message({
            type: "warning",
            message: "请输入商家",
          });
          b = false;
          return
        }
        if (element.money==null) {
          this.$message({
            type: "warning",
            message: "请输入金额",
          });
          b = false;
          return
        }
        if (element.proportion==null) {
          this.$message({
            type: "warning",
            message: "请输入比例",
          });
          b = false;
          return
        }
      });
      if (b == false) {
        return;
      }

      if (this.form.money==null) {
        this.$message({
          type: "warning",
          message: "请输入金额",
        });
        return;
      }
      if (!this.form.proportion==null) {
        this.$message({
          type: "warning",
          message: "请输入比例",
        });
        return;
      }
      if (!this.form.account) {
        this.$message({
          type: "warning",
          message: "请输入收款账号",
        });
        return;
      }
      if (this.form.pay_method == "loginName" && !this.form.name) {
        this.$message({
          type: "warning",
          message: "请输入账号真实姓名",
        });
        return;
      }
      if (this.form.is_balance_strike<0) {
        this.$message({
          type: "warning",
          message: "请选择是否结余再分账",
        });
        return;
      }
      if (!this.form.weight) {
        this.$message({
          type: "warning",
          message: "请输入优先级",
        });
        return;
      }
      this.btnLoading = true;
      this.form.school_id = this.school_id;
      request({
        url: "/api/backend/school/ledgerRoleStore",
        method: "post",
        data: this.form,
      })
        .then(() => {
          this.btnLoading = false;
          this.dialogVisible = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .finally(() => {
          this.btnLoading = false;
        });
    },
    handleToPhones(item) {
      this.$router.push(`/school/ledgerRolePhone?ledger_role_id=${item.id}`);
    },
    revenueLog(item) {
      this.$router.push(`/school/revenueLog?ledger_role_id=${item.id}`);
    },
    show(item) {
      this.pay_method.id = item.id;
      this.pay_method.pay_method = item.pay_method;
      this.dialogVisible1 = true;
    },
    addShop() {
      let obj = {
        shop_id: null,
        money: null,
        proportion: null,
      };
      this.form.shops.push(obj);
    },
    delShop(idx) {
      this.form.shops.splice(idx, 1);
    },
    handleStore() {
      this.$confirm("确定修改学校的支付方式吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        request({
          url: "/api/backend/school/payMethodStore",
          method: "get",
          params: this.pay_method,
        }).then(() => {
          this.dialogVisible1 = false;
          this.getList();
          this.$store.dispatch("GetSchools");
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 6px;
}
.el-tag {
  margin-right: 10px;
}
.button-new-tag {
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 140px;
  vertical-align: bottom;
}
.input {
  display: flex;
}
</style>
